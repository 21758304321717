'use client';

import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider, PersistedClient, Persister } from '@tanstack/react-query-persist-client';
import { del, get, set } from 'idb-keyval';
import React, { useState } from 'react';

const ReactQueryDevtoolsProduction = React.lazy(() =>
	import('@tanstack/react-query-devtools/production').then((d) => ({
		default: d.ReactQueryDevtools,
	})),
);

export function ReactQueryProvider({ children }: React.PropsWithChildren) {
	const [client] = useState(
		new QueryClient({
			defaultOptions: {
				queries: {
					gcTime: 1000 * 60 * 15, // * 24 * 2, // 48 hours
					refetchOnWindowFocus: false,
				},
			},
		}),
	);

	return <PersistedQueryClientProvider client={client}>{children}</PersistedQueryClientProvider>;
}

const PersistedQueryClientProvider = ({
	children,
	client,
}: React.PropsWithChildren<{ client: QueryClient; organizationId?: string }>) => {
	const [persister] = useState(
		createSyncStoragePersister({ storage: typeof window !== 'undefined' ? window.localStorage : undefined }),
	);

	return (
		<PersistQueryClientProvider
			client={client}
			persistOptions={{
				dehydrateOptions: { shouldDehydrateMutation: () => false },
				// hydrateOptions: { defaultOptions: { mutations: { gcTime: 1000 * 60 * 60 * 24 } } },
				persister,
			}}
		>
			{children}
		</PersistQueryClientProvider>
	);
};

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
export function createIDBPersister(idbValidKey: IDBValidKey = 'reactQuery') {
	return {
		persistClient: async (client: PersistedClient) => {
			// console.log('persist client', ++counter);
			await set(idbValidKey, client);
		},
		removeClient: async () => {
			await del(idbValidKey);
		},
		restoreClient: async () => {
			return await get<PersistedClient>(idbValidKey);
		},
	} as Persister;
}
