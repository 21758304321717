'use client';

import { deDE } from '@clerk/localizations';
import { ClerkProvider } from '@clerk/nextjs';
import { dark } from '@clerk/themes';
import { deepMerge, useComputedColorScheme } from '@mantine/core';
import { ComponentProps, PropsWithChildren, useEffect } from 'react';

const disableInput = (element: HTMLInputElement) => {
	element.disabled = true;
	element.ariaDisabled = 'true';
};

// Create a MutationObserver instance

const observer =
	typeof window === 'undefined'
		? null
		: new MutationObserver((mutations) => {
				mutations.forEach((mutation) => {
					if (mutation.type === 'childList') {
						mutation.addedNodes.forEach((node) => {
							// Check if it's the element we're interested in
							if (node.nodeType === Node.ELEMENT_NODE) {
								// disableInput(node as HTMLInputElement);
								// Check all child elements
								(node as HTMLElement)
									.querySelectorAll('.cl-formFieldInput.cl-input.cl-formFieldInput__slug.cl-input__slug.🔒️')
									.forEach((el) => disableInput(el as HTMLInputElement));
							}
						});
					}
				});
			});

type Appearance = ComponentProps<typeof ClerkProvider>['appearance'];

export const AuthProvider: React.FC<PropsWithChildren<{ theme?: 'light' | 'dark'; publishableKey?: string }>> = ({
	children,
	publishableKey,
	theme,
}) => {
	const computedTheme = useComputedColorScheme();
	theme = computedTheme;

	useEffect(() => {
		// Start observing the target node for configured mutations
		observer?.observe(document.body, {
			childList: true,
			subtree: true,
		});

		return () => {
			observer?.disconnect();
		};
	});

	return (
		<ClerkProvider
			publishableKey={publishableKey}
			appearance={deepMerge(
				{
					layout: {},
					variables: {
						borderRadius: '4px',
						fontFamily: `var(--mantine-font-family)`,
					},
				} satisfies Appearance,
				theme === 'dark'
					? ({
							baseTheme: dark,
							variables: {
								colorBackground: 'rgb(15 15 15)',
							},
						} satisfies Appearance)
					: ({} satisfies Appearance),
			)}
			localization={deepMerge(deDE, {
				organizationProfile: {
					invitePage: {
						selectDropdown__role: 'Rolle auswählen',
					},
				},
				userProfile: {
					formButtonPrimary__remove: 'Entfernen',
					formButtonPrimary__save: 'Speichern',
					formButtonReset: 'Abbrechen',
					passkeyScreen: {},
				},
			} satisfies typeof deDE)}
		>
			{children}
		</ClerkProvider>
	);
};
