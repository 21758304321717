import(/* webpackMode: "eager", webpackExports: ["SentryGlobalExceptionHandler"] */ "/vercel/path0/apps/frachter/src/components/SentryGlobalExceptionHandler.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/vercel/path0/apps/frachter/src/context/AuthContext/AuthContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryProvider"] */ "/vercel/path0/apps/frachter/src/context/ReactQueryContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCProvider"] */ "/vercel/path0/apps/frachter/src/trpc/TRPCProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/vercel/path0/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/vercel/path0/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["closeAllModals","closeModal","modals","openConfirmModal","openContextModal","openModal"] */ "/vercel/path0/node_modules/@mantine/modals/esm/events.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ModalsProvider"] */ "/vercel/path0/node_modules/@mantine/modals/esm/ModalsProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useModals"] */ "/vercel/path0/node_modules/@mantine/modals/esm/use-modals/use-modals.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Notifications"] */ "/vercel/path0/node_modules/@mantine/notifications/esm/Notifications.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["cleanNotifications","cleanNotificationsQueue","createNotificationsStore","hideNotification","notifications","notificationsStore","showNotification","updateNotification","updateNotificationsState","useNotifications"] */ "/vercel/path0/node_modules/@mantine/notifications/esm/notifications.store.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["theme"] */ "/vercel/path0/packages/theme/styles/mantine-theme.ts");
