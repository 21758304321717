import { parseEnv } from 'znv/compat';
import { z } from 'zod';

import type * as _ from '../../../../node_modules/znv/dist/util/type-helpers';

export const envVars = {
	NEXT_PUBLIC_CLERK_JWT_KEY: process.env.NEXT_PUBLIC_CLERK_JWT_KEY,
	NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
	NEXT_PUBLIC_CLERK_SIGN_IN_FALLBACK_REDIRECT_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_IN_FALLBACK_REDIRECT_URL,
	NEXT_PUBLIC_CLERK_SIGN_IN_FORCE_REDIRECT_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_IN_FORCE_REDIRECT_URL,
	NEXT_PUBLIC_CLERK_SIGN_IN_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_IN_URL,
	NEXT_PUBLIC_CLERK_SIGN_UP_FALLBACK_REDIRECT_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_UP_FALLBACK_REDIRECT_URL,
	NEXT_PUBLIC_CLERK_SIGN_UP_FORCE_REDIRECT_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_UP_FORCE_REDIRECT_URL,
	NEXT_PUBLIC_CLERK_SIGN_UP_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_UP_URL,
	NEXT_PUBLIC_DEFAULT_COLOR_SCHEME: process.env.NEXT_PUBLIC_DEFAULT_COLOR_SCHEME,
	NEXT_PUBLIC_GOOGLE_ANALYTICS_ID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
	NEXT_PUBLIC_GOOGLE_ANALYTICS_LOGIN_ID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_LOGIN_ID,
	NEXT_PUBLIC_JWT_AUDIENCE_CLOUDPRINT: process.env.NEXT_PUBLIC_JWT_AUDIENCE_CLOUDPRINT,
	NEXT_PUBLIC_ORG_ID_FRACHTER: process.env.NEXT_PUBLIC_ORG_ID_FRACHTER,
	NEXT_PUBLIC_SITE_URL: process.env.NEXT_PUBLIC_SITE_URL,
	NEXT_PUBLIC_SITE_URL_PRODUCTION: process.env.NEXT_PUBLIC_SITE_URL_PRODUCTION,
};

export const DEFAULTS = {
	NEXT_PUBLIC_CLERK_JWT_KEY: 'FIX_NEXTJS',
	NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: 'FIX_NEXTJS',
	NEXT_PUBLIC_CLERK_SIGN_IN_FALLBACK_REDIRECT_URL: 'FIX_NEXTJS',
	NEXT_PUBLIC_CLERK_SIGN_IN_FORCE_REDIRECT_URL: 'FIX_NEXTJS',
	NEXT_PUBLIC_CLERK_SIGN_IN_URL: 'FIX_NEXTJS',
	NEXT_PUBLIC_CLERK_SIGN_UP_FALLBACK_REDIRECT_URL: 'FIX_NEXTJS',
	NEXT_PUBLIC_CLERK_SIGN_UP_FORCE_REDIRECT_URL: 'FIX_NEXTJS',
	NEXT_PUBLIC_CLERK_SIGN_UP_URL: 'FIX_NEXTJS',
};

export const envSchema = {
	NEXT_PUBLIC_CLERK_JWT_KEY: z.string(),
	NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: z.string(),
	NEXT_PUBLIC_CLERK_SIGN_IN_FALLBACK_REDIRECT_URL: z.string(),
	NEXT_PUBLIC_CLERK_SIGN_IN_FORCE_REDIRECT_URL: z.string().default('https://www.frachter.app/app/org'),
	NEXT_PUBLIC_CLERK_SIGN_IN_URL: z.string(),
	NEXT_PUBLIC_CLERK_SIGN_UP_FALLBACK_REDIRECT_URL: z.string(),
	NEXT_PUBLIC_CLERK_SIGN_UP_FORCE_REDIRECT_URL: z.string().default('https://www.frachter.app/app/org'),
	NEXT_PUBLIC_CLERK_SIGN_UP_URL: z.string(),
	NEXT_PUBLIC_DEFAULT_COLOR_SCHEME: z.enum(['dark', 'light']).default('dark'),
	NEXT_PUBLIC_GOOGLE_ANALYTICS_ID: z.string().default('G-T1DJ99VFKH'),
	NEXT_PUBLIC_GOOGLE_ANALYTICS_LOGIN_ID: z.string().default('G-ZDR4VV5YSX'),
	NEXT_PUBLIC_JWT_AUDIENCE_CLOUDPRINT: z
		.literal('https://cloudprint.frachter.app')
		.default('https://cloudprint.frachter.app'),
	NEXT_PUBLIC_ORG_ID_FRACHTER: z.string().default('org_e3f4ec1f7d3'),
	NEXT_PUBLIC_SITE_URL: z.string().default(''),
	NEXT_PUBLIC_SITE_URL_PRODUCTION: z.string().default('https://www.frachter.app'),
};

// HINT: do not use destructuring as envalid sets non-enumarable properties
export const env = parseEnv(process.env.NEXT_PHASE === 'phase-production-build' ? DEFAULTS : envVars, envSchema);
