'use client';

import {
	createTRPCClient,
	CreateTRPCClientOptions,
	createTRPCReact,
	loggerLink,
	unstable_httpBatchStreamLink,
} from '@trpc/react-query';
import superjson from 'superjson';

import type { AppRouter } from '@/trpc';

import { env } from '@/data/env';

export const trpc = createTRPCReact<AppRouter>({
	overrides: {
		useMutation: {
			async onSuccess(opts) {
				await opts.originalFn();
				await opts.queryClient.invalidateQueries();
			},
		},
	},
});

function getBaseUrl() {
	if (typeof window !== 'undefined')
		// browser should use relative path
		return '';
	if (process.env.VERCEL_URL)
		// reference for vercel.com
		return `https://${process.env.VERCEL_URL}`;
	// assume localhost
	return env.NEXT_PUBLIC_SITE_URL;
}

export const getTrpcOptions: (options?: { headers?: Headers }) => CreateTRPCClientOptions<AppRouter> = (options) => ({
	links: [
		loggerLink({
			enabled: () => true,
		}),
		unstable_httpBatchStreamLink({
			fetch: (input, init) => {
				// return fetch(input, { ...init, next: { revalidate: 60 * 5 } });
				// cache: 'xyz' works, but then we break ably as this one always needs a fresh token
				// HINT: this would now work
				return fetch(input, { ...init, /* cache: 'force-cache', */ next: { revalidate: 60 * 5 } });
			},
			headers() {
				const headers = new Map(options?.headers);
				headers.set('x-trpc-source', 'nextjs-react');
				return Object.fromEntries(headers);
			},
			transformer: superjson,
			url: `${getBaseUrl()}/api/trpc`,
		}),

		// httpBatchLink({
		// 	url: `${getBaseUrl()}/api/trpc`,
		// 	transformer: superjson,
		// }),
	],
});

export const trpcVanilla = createTRPCClient<AppRouter>({
	...getTrpcOptions(),
});
