'use client';

import { useQueryClient } from '@tanstack/react-query';
import React, { PropsWithChildren, useState } from 'react';

import { getTrpcOptions, trpc } from './trpc-client';

export const TRPCProvider: React.FC<PropsWithChildren<{ headers?: Headers }>> = ({ children, headers }) => {
	const queryClient = useQueryClient();

	const [trpcClient] = useState(() =>
		trpc.createClient({
			...getTrpcOptions({ headers }),
		}),
	);
	return (
		<trpc.Provider client={trpcClient} queryClient={queryClient}>
			{children}
		</trpc.Provider>
	);
};
