'use client';

import * as Sentry from '@sentry/nextjs';
import React from 'react';

declare global {
	// eslint-disable-next-line no-var
	var captureException: (typeof Sentry)['captureException'] | undefined;
}

export const SentryGlobalExceptionHandler = () => {
	React.useEffect(() => {
		if (typeof window !== 'undefined' && !globalThis.captureException) {
			globalThis.captureException = Sentry.captureException.bind(Sentry);
		}
	}, []);

	return null;
};
